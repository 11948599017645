.active::before {
  content: '';
  position: absolute;
  left: 78px;
  bottom: 0px;
  display: inline-block;
  width: 110px;
  height: 2px;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translate(-50%);
  background-color: #487bb1;
}
